@import '@/styles/common.scss';

i {
  font-size: calc(1vw * #{$heightRatio} * v-bind(fontSize));
  color: v-bind(color);
}
span {
  // font-size: 16px;
  font-size: calc(1vw * #{$heightRatio} * v-bind(fontSize));
  color: v-bind(color);
  transition: color 0.3s;
  display: flex;
  align-items: center;
}
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  cursor: pointer;
}
