@import '@/styles/common.scss';

.alease_title_left,
.alease_title_right {
  position: relative;
  font-family: SJyunhei;
  font-weight: 500;
  color: #ffffff;
  // line-height: 28px;
  @include Width(300);
  z-index: 100;
  // width: 100%;
  @include hHeight(36);
  @include FontSize(16);
  @include LineHeight(28);
  @include LetterSpacing(1);
  @include MarginBottom(5);
}
.alease_title_left {
  @include Padding(3, 0, 0, 30);

  left: 0;
}
.alease_title_right {
  @include Padding(3, 0, 0, 30);
  // @include Right(-45);
  right: 0;
}
.alease_title_left::before {
  position: absolute;

  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: url("~@/assets/images/基础框架通用元素切图/二级标题/短.png")
    no-repeat;
  background-size: 100% 100%;
}
.alease_title_right::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: url("~@/assets/images/基础框架通用元素切图/二级标题/短.png")
    no-repeat;
  background-size: 100% 100%;
  transform: rotateY(-180deg);
}
